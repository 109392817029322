.spinner {
    
    animation: spin 1s linear infinite;
    height:40px;
    width:40px;
    border-radius:50%;
    
}

.spinnerWrapper {
    height: 100%;
    
}

@keyframes spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

